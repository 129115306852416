import './Navbar.scss';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setButtonState, setSidebarButton, setTableButton, setImportButton, setPrintButton, setExportButton, setScreenshotButton } from '../../../state/features/buttonStateSlice';
import { setDrawState } from '../../../state/features/drawStateSlice';
import { setChildKey, setPanoramaType } from '../../../state/features/panoramaSlice';
import { setSelectedFeature, setVertexFeature } from '../../../state/features/selectedFeatureSlice';
import { useAppSelector } from '../../../state/hooks';
import { DrawStateType, MultiDrawTypes } from '../../../util/model/drawState';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { setShowImport } from '../../../state/features/importSlice';
import { setShowPrint } from '../../../state/features/printSlice';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { setGoToSelection } from '../../../state/features/tableSlice';
import { setShowExport } from '../../../state/features/exportSlice';
import { WKT } from 'ol/format';
import Feature from 'ol/Feature';
import axios from 'axios';
import { Language } from '../../language/Language';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Translate } from 'ol/interaction';
import { setCancelDigiActivate, setDigiPopupVisibility, setDrawWithDigi, setEnabledByMemorize, setFrom } from '../../../state/features/digitizationSlice';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { setSelectedLayer } from '../../../state/features/layerSlice';
import { MultiLineString } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';
import PasswordChangeForm from '../../forgotPassword/PasswordChangeForm';
import '../../forgotPassword/PasswordChangeForm.scss';
import LoadingScreen from '../../shared/loading/LoadingScreen';

export const Navbar = ({ showMessage }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const buttonState = useAppSelector(state => state.buttonState.buttonState)
  const tableVisibility = useAppSelector(state => state.buttonState.tableButton)
  const sidebarVisibility = useAppSelector(state => state.buttonState.sidebarButton)
  const screenshotVisibility = useAppSelector(state => state.buttonState.screenshotButton)
  const drawState = useAppSelector(state => state.drawState.value)
  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
  const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
  const user = useAppSelector(state => state.login.user);
  const isLayerVisible = useAppSelector(state => state.layer.isLayerVisible);
  const selectedTableRow = useAppSelector(state => state.table.selectedRow);
  const [searchText, setSearchText] = useState<string>('');
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const [searchInterval, setSearchInterval] = useState<any>(undefined);
  const currentLanguage = useAppSelector(state => state.language.value)
  const [quickSearchList, setQuickSearchList] = useState<any[]>([]);
  const [displaySettings, setDisplaySettings] = useState<boolean>(false);
  const [googleCoordinateMode, setGoogleCoordinateMode] = useState<boolean>(false);
  const [panoType, setPanoType] = useState({ type: 1, label: (t('BUTTON.Floor')) })
  const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
  const [GIS_PROJECT, setGIS_PROJECT] = useState<string>("");
  const toast = useRef<any>(null);
  const panoramaType = useAppSelector(state => state.panorama.type);
  const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);
  const enabledByMemorize: any = useAppSelector(state => state.digitization.enabledByMemorize);
  const [metricType, setMetricType] = useState<number>(localStorage.getItem("setMetricType") ? +(localStorage.getItem("setMetricType")!) : 3);
  const childKey:any = useAppSelector(state => state.panorama.childKey);
  const isMobileScreen: number = useAppSelector((state: any) => state.styleControl.isMobileScreen)
  const [IsChangePassWord ,  setChangePassWord] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [IsPasswordForm , setPasswordForm ] = useState(false);

  
  const handlePasswordFormClose = () => {

       setPasswordForm(false);
   };

  const menuItems = [
    {
      label: t('BUTTON.Settings'), icon: 'pi pi-cog', command: () => {
        setDisplaySettings(true)
      }
    },
    {
      label: t('BUTTON.LOGOUT'), icon: 'pi pi-sign-out', command: () => {
        logOutConfirm()
      }
    }
  ]
  // const menuItemsForAdmin = [
  //   { label: 'Panel', icon: 'pi pi-th-large', url: `${process.env.REACT_APP_BASE_URL}panel` },
  //   {
  //     label: 'Ayarlar', icon: 'pi pi-cog', command: () => {
  //       setDisplaySettings(true)
  //     }
  //   },
  //   {
  //     label: t('BUTTON.LOGOUT'), icon: 'pi pi-sign-out', command: () => {
  //       logOutConfirm()
  //     }
  //   }
  // ]
  
  const selectedLayerTable: any = useAppSelector(state => state.layer.selectedLayerTable);
  const [drawButtons, setDrawButtons] = useState<({ svg: string; state: DrawStateType; } | { svg: string; state: MultiDrawTypes; })[]>(
    
    +(window as any).innerWidth > 680 ?
    [
    {
      svg: 'icons/point.svg',
      state: DrawStateType.POINT,
    },
    {
      svg: 'icons/line.svg',
      state: DrawStateType.LINESTRING,
    },
    {
      svg: 'icons/polygon.svg',
      state: DrawStateType.POLYGON,
    },
    {
      svg: 'icons/height-draw.svg',
      state: DrawStateType.HLINE,
    },
    {
      svg: 'icons/perpendicular-draw.svg',
      state: DrawStateType.ROAD_LINE,
    },
    {
      svg: 'icons/grid-draw.svg',
      state: DrawStateType.GRID,
    },
    {
      svg: 'icons/clear.svg',
      state: DrawStateType.CLEAR,
    }
  ]:
  [
    {
      svg: 'icons/point.svg',
      state: DrawStateType.POINT,
    },
    {
      svg: 'icons/line.svg',
      state: DrawStateType.LINESTRING,
    },
    {
      svg: 'icons/polygon.svg',
      state: DrawStateType.POLYGON,
    },
    {
      svg: 'icons/clear.svg',
      state: DrawStateType.CLEAR,
    }]
)

  const metricSettings: any = [{
      label: 'MILE',
      value: 1
    },{
      label: 'FEET',
      value: 2
    },{
      label: 'METER',
      value: 3
    },{
      label: 'KM',
      value: 4
    }
  ]
  const [digiButtons, setDigiButtons] = useState(
    [
    {
      svg: 'icons/add-object-icons/nokta_sayisallastirma.svg',
      state: ButtonState.ADD,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/duzenleme.svg',
      state: ButtonState.EDIT,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/vertex_duzenleme.svg',
      state: ButtonState.VERTEX,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/veri_silme.svg',
      state: ButtonState.DELETE,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/veri_kesme.svg',
      state: ButtonState.SPLIT,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/veri_tasima.svg',
      state: ButtonState.MOVE,
      isDisabled: false
    },
    {
      svg: 'icons/add-object-icons/veri_paralel.svg',
      state: ButtonState.PARALLEL,
      isDisabled: false
    }
  ])
  const PanoTypes = [
    { type: 1, label: (t('BUTTON.Floor')) },
    { type: 2, label: (t('BUTTON.Point Cloud')) },
    { type: 3, label: (t('BUTTON.Multi Display')) }
  ];

  useEffect(() => {
    const panoramaType: any = localStorage.getItem('panoramaType');
    if(panoramaType){
        togglePanoType(JSON.parse(panoramaType))
    }

    // dispatch(setSidebarButton(true))
    setGoogleCoordinateMode(localStorage.getItem('setGoogleFormat') === 'true' ? true : false)
    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/customize`)
      .then((response: any) => {
        setGIS_PROJECT(response.data.customize.company_name)
      }).catch((err: any) => {
      });
      (window as any).eventStatusList = [];
  }, []);

  /////// nokta bulutu ile sayıasallşatırmayı açında draw point fonksiyonnu açıyo
  useEffect(() => {
    const drawButtons_ = [
      panoType.type === 1 ? {
        svg: 'icons/point.svg',
        state: DrawStateType.POINT
      } : panoType.type === 2 ?
        {
          svg: 'icons/point.svg',
          state: DrawStateType.PANORAMA_POİNT_PC
        } : {
          svg: 'icons/point.svg',
          state: MultiDrawTypes.POINT_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/line.svg',
        state: DrawStateType.LINESTRING
      } : panoType.type === 2 ?
        {
          svg: 'icons/line.svg',
          state: DrawStateType.PANORAMA_LINE_PC
        } : {
          svg: 'icons/line.svg',
          state: MultiDrawTypes.LINE_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/polygon.svg',
        state: DrawStateType.POLYGON,
      } : panoType.type === 2 ?
        {
          svg: 'icons/polygon.svg',
          state: DrawStateType.PANORAMA_POLYGON_PC
        } : {
          svg: 'icons/polygon.svg',
          state: MultiDrawTypes.POLYGON_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/height-draw.svg',
        state: DrawStateType.HLINE,
      } : panoType.type === 2 ?
        {
          svg: 'icons/height-draw.svg',
          state: DrawStateType.HLINE
        } : {
          svg: 'icons/triangle-draw.svg',
          state: MultiDrawTypes.HLINE_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/perpendicular-draw.svg',
        state: DrawStateType.ROAD_LINE
      } : panoType.type === 2 ?
        {
          svg: 'icons/triangle-draw.svg',
          state: DrawStateType.PANORAMA_TRIANGLE_PC
        } : {
          svg: 'icons/square-draw.svg',
          state: MultiDrawTypes.SQUARE_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/grid-draw.svg',
        state: DrawStateType.GRID
      } : panoType.type === 2 ?
        {
          svg: 'icons/square-draw.svg',
          state: DrawStateType.PANORAMA_SQUARE_PC
        } : {
          svg: 'icons/clear-draw.svg',
          state: DrawStateType.CLEAR
        },

      {
        svg: 'icons/clear.svg',
        state: DrawStateType.CLEAR
      }
    ]
    if (panoType.type === 3) {
      drawButtons_.splice(5, 1)
    }
    // setDrawButtons(drawButtons_)
    if(+(window as any).innerWidth > 680){
      setDrawButtons(drawButtons_)
    }else{
      setDrawButtons(drawButtons_.slice(0, 3).concat(drawButtons_[6]).concat([{
        svg: 'icons/complete.svg',
        state: DrawStateType.FINISH
      }]))
    }
    // if(!isMobileScreen){
    //    setDrawButtons(drawButtons_)
    //   //  setDrawButtons(drawButtons_.slice(0, 3).concat(drawButtons_[6]).concat([{
    //   //   svg: 'icons/measureComplete.svg',
    //   //   state: DrawStateType.FINISH
    //   // }]))
    // }else{
    //   setDrawButtons(drawButtons_.slice(0, 3).concat(drawButtons_[6]).concat([{
    //     svg: 'icons/complete.svg',
    //     state: DrawStateType.FINISH
    //   }]))
    // }
    
  }, [panoType])

  useEffect(() => {
    if (googleCoordinateMode) {
      localStorage.setItem('setGoogleFormat', 'true')
    }
    else {
      localStorage.setItem('setGoogleFormat', 'false')
    }
  }, [googleCoordinateMode])

  useEffect(() => {
    function handleKeyDown(event: any) {
      const btnstate: any = (window as any).buttonState;
      const isDigibtn: any = btnstate === ButtonState.ADD || btnstate === ButtonState.EDIT || btnstate === ButtonState.VERTEX ||
        btnstate === ButtonState.DELETE || btnstate === ButtonState.HELP || btnstate === ButtonState.INFO || btnstate === ButtonState.HOLE ||
        btnstate === ButtonState.TOUR || btnstate === ButtonState.SPLIT || btnstate === ButtonState.MOVE || btnstate === ButtonState.PARALLEL ||
        btnstate === ButtonState.IMPORT || btnstate === ButtonState.EXPORT || btnstate === ButtonState.PRINT;

      if (event.key === 'Escape' && isDigibtn) {
        dispatch(setButtonState(ButtonState.NONE));
        dispatch(setSelectedFeature([]))
        dispatch(setVertexFeature([]))
        cancelDigiActivate()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
  }, []);
  useEffect(() => {
    if (searchInterval) {
      clearInterval(searchInterval);
    }
    const token = localStorage.getItem('token');
    if (searchText.length > 2) {
      const searchTimeout = setTimeout(() => {
        axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/search?search=${searchText}&token=${token}`)
          .then(response => {
            setQuickSearchList(response.data.result);
          })
      }, 250);
      setSearchInterval(searchTimeout);
    }
  }, [searchText])

  useEffect(() => {
    if (currentLanguage) {
      const panoramaType: any = localStorage.getItem('panoramaType');
      if(panoramaType){
          const data_ :any = JSON.parse(panoramaType)
          // console.log("label :",data_.label)
          // console.log("type :",data_.type)
          const data: any = PanoTypes.find((x) => x.type === data_.type)
          setPanoType({ type: data.type, label: data.label })
          localStorage.setItem('panoramaType', JSON.stringify({
            label: data.label,
            type: data.type
          }))
          // togglePanoType(JSON.parse(panoramaType))
      }else{
          const data: any = PanoTypes.find((x) => x.type === panoType.type)
          setPanoType({ type: panoType.type, label: data?.label })
          localStorage.setItem('panoramaType', JSON.stringify({
            label: currentLanguage === "tr" ? "Zemin" : "Floor",
            type: 1
          }))
      }

      // const data: any = PanoTypes.find((x) => x.type === panoType.type)
      // setPanoType({ type: panoType.type, label: data?.label })
      
      setTimeout(() => {
        dispatch(setButtonState(ButtonState.NONE))
        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
      }, 200);
    }
  }, [currentLanguage, t])
  const clickOnQuickSearch = async (e: any) => {
    const searchFeature = e.value;
    const wktFormat = new WKT();
    const geometry = wktFormat.readGeometry(searchFeature.st_astext);
    const feature = new Feature({ geometry });
    feature.getGeometry()?.transform('EPSG:4326', 'EPSG:3857')

    dispatch(setSelectedFeature([feature]))
    dispatch(setGoToSelection(true))
    /*  setQuickSearchList([]);
     setSearchText('') */
  }

  const cancelDigiActivate = () => {
    dispatch(setDigiPopupVisibility(false))
    dispatch(setCancelDigiActivate(true))
    dispatch(setFrom(""))
  }

  const changeDigiOperations = (button: any) => {
    setTimeout(() => {
      if (buttonState === button.state) {
                      
        if(buttonState === ButtonState.MOVE || buttonState === ButtonState.VERTEX){
          dispatch(setDigiPopupVisibility(false))
          dispatch(setCancelDigiActivate(true));
          dispatch(setFrom(""))
        }

        dispatch(setButtonState(ButtonState.NONE))
      }
      else if (selectedFeature[selectedFeature.length - 1] && selectedFeature[selectedFeature.length - 1].geometry.type !== "MultiLineString" && button.state === 'SPLIT') {
        showMessage(t(`BUTTON.ALERTSPLIT2`))
        dispatch(setButtonState(ButtonState.NONE))
      } else if (!selectedFeature.length && button.state === 'SPLIT') {
        // toast.current?.show({ severity: 'info', detail: (t(`BUTTON.ALERTSPLIT`)), life: 3000 })
        showMessage(t(`BUTTON.ALERTSPLIT`))
        dispatch(setButtonState(ButtonState.NONE))
      }
      else if (!selectedFeature.length && button.state === 'PARALLEL') {
        showMessage(t(`BUTTON.ALERTPARALLEL`))
        dispatch(setButtonState(ButtonState.NONE))
      } /*else if (!selectedFeature.length && button.state === 'MOVE') {
        showMessage(t(`BUTTON.ALERTMOVE`))
        
        dispatch(setButtonState(ButtonState.NONE))
      }else if (selectedFeature.length && selectedFeature[0].id.split('.')[0] !== selectedLayer.name && (button.state === 'MOVE' || button.state === 'SPLIT')) {
        showMessage(t('TEXT.Continue Your Process On The Selected Layer!'))
        dispatch(setSelectedFeature([]))
      }*/
      else {
        if (button.state === ButtonState.EDIT || button.state === ButtonState.VERTEX || button.state === ButtonState.DELETE) {
          /** clear selection ! */
/*           dispatch(setSelectedFeature([]))  To MAKE BUTTON SELECTİON WORK BOTH WAYS 
 */          dispatch(setVertexFeature([]))
        }
        dispatch(setButtonState(ButtonState.NONE))
        dispatch(setButtonState(button.state))
      }
    }, 100);
  }

  const toggleSidebar = () => {
    if (sidebarVisibility) {
      document.getElementsByClassName("home")[0].classList.remove('sidebar-start');
      dispatch(setSidebarButton(false))
    }
    else {
      document.getElementsByClassName("home")[0].classList.add('sidebar-start');
      dispatch(setSidebarButton(true))
    }
  }

  const togglePanoType = (value: any) => {
    setPanoType(value)
    if (value.type === 1) {
      (window as any).panoramaType = panoramaType;
      dispatch(setPanoramaType('Floor'));
      localStorage.setItem('panoramaType', JSON.stringify({
        label: currentLanguage === "tr" ? "Zemin" : "Floor",
        type: 1
      }))
    }
    if (value.type === 2) {
      (window as any).panoramaType = panoramaType;
      dispatch(setPanoramaType('Point Cloud'));
      localStorage.setItem('panoramaType', JSON.stringify({
        label: currentLanguage === "tr" ? "Nokta Bulutu" : "Point Cloud",
        type: 2
      }))
    }
    if (value.type === 3) {
      (window as any).panoramaType = panoramaType;
      dispatch(setPanoramaType('Multi Display'));
      localStorage.setItem('panoramaType', JSON.stringify({
        label: currentLanguage === "tr" ? "Çoklu Ekran" : "Multi Display",
        type: 3
      }))
    }
    dispatch(setDigiPopupVisibility(false))
  }

  // useEffect(() => {
  //   if(selectedLayer.id &&  panoramaType === 'Point Cloud'){
  //     switch (selectedLayer.geomtype) {
  //       case 'MultiPointZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_POİNT_PC}))
  //         break;
  //       case 'MultiLineStringZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_LINE_PC}))
  //         break;
  //       case 'MultiPolygonZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_POLYGON_PC}))
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [selectedLayer, panoType, buttonState])


  useEffect(() => {
    const geomType: any = selectedLayerTable.geomtype;
    const buttonPermission: any = selectedLayerTable.perm

    if (geomType !== undefined) {
      let updatedSvg: any
      if (geomType === "MultiPoint" || geomType === "MultiPointZ") {
        updatedSvg = "icons/add-object-icons/nokta_sayisallastirma.svg";
      } else if (geomType === "MultiLineString" || geomType === "MultiLineStringZ") {
        updatedSvg = "icons/add-object-icons/cizgi_sayisallastirma.svg";
      } else if (geomType === "MultiPolygon" || geomType === "MultiPolygonZ") {
        updatedSvg = "icons/add-object-icons/alan_sayisallastirma.svg";
      }
      setDigiButtons(digiButtons.map((button: any) => {
        if (button.state === ButtonState.ADD) {
          return { ...button, svg: updatedSvg, isDisabled: buttonPermission === 1 ? true : false }
        }
        else if (button.state === ButtonState.DELETE) {
          return { ...button, isDisabled: (buttonPermission === 3 || buttonPermission === 1) ? true : false }
        }
        else {
          return { ...button, isDisabled: buttonPermission === 1 ? true : false }
        }
        // return button
      }))
      
    }
  }, [selectedLayerTable]);

  const changeDrawState = (buttonState: any) => {
    // console.log(buttonState);
    
    if (buttonState === drawState.drawStateType) {
      dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))

      // if(isMobileScreen){
      //   // console.log("true");
      //   (window as any).mobileDrawPoint = true;
      // }
    }
    else {
      // if(panoType.type === 2){
      //   toast.current?.show({ severity: 'info', detail: ('Veri bulunamadı'), life: 355000 });
      // }
      setTimeout(() => {
        // if(isMobileScreen){
        //   // console.log("false");
        //   (window as any).mobileDrawPoint = false;
        // }
        dispatch(setDrawState({ drawStateType: buttonState }))
      }, 100);
    }
  }

  const toggleHole = () => {
    // cancelDigiActivate()

    if (buttonState === ButtonState.HOLE) {
      dispatch(setButtonState(ButtonState.NONE))
      /** clear selection ! */
      dispatch(setSelectedFeature([]))
    }
    else {
      dispatch(setButtonState(ButtonState.HOLE))
    }
  }

  const toggleInfo = () => {
    // cancelDigiActivate()
    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
    dispatch(setSelectedFeature([]))
    // dispatch(setVertexFeature([]))

    setTimeout(() => {
      if (buttonState === ButtonState.INFO) {
        dispatch(setButtonState(ButtonState.NONE))
        /** clear selection ! */
        dispatch(setSelectedFeature([]))
        dispatch(setVertexFeature([]))
      }
      else {
        dispatch(setButtonState(ButtonState.INFO))
      }
    }, 100);
  }

  const toggleSelection = () => {
    dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
    // cancelDigiActivate()
    dispatch(setSelectedFeature([]))
    // dispatch(setVertexFeature([]))
    setTimeout(() => {
      if (buttonState === ButtonState.SELECTION) {
        dispatch(setButtonState(ButtonState.NONE))
        /** clear selection ! */
        dispatch(setSelectedFeature([]))
        dispatch(setVertexFeature([]))
      }
      else {
        const intrcts = (window as any).olMap.getInteractions().getArray()
        intrcts.forEach((interaction: any) => {
          if (interaction instanceof Translate/* || interaction instanceof Modify*/) {
            (window as any).olMap.removeInteraction(interaction)
          }
        });
        dispatch(setButtonState(ButtonState.SELECTION))
      }
    }, 100);
  }

  const toggleTable = () => {
    if (tableVisibility) {
      dispatch(setTableButton(false))
      // dispatch(setSelectedLayer({}))
    }
    else {
      dispatch(setTableButton(true))
    }
  }

  const toggleHelp = () => {
    if (buttonState === ButtonState.HELP) {
      dispatch(setButtonState(ButtonState.NONE))
    }
    else {
      dispatch(setButtonState(ButtonState.HELP))
    }
  }
  const toggleTour = () => {
    if (buttonState === ButtonState.TOUR) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setSidebarButton(false))
    }
    else {
      dispatch(setButtonState(ButtonState.TOUR))
      dispatch(setSidebarButton(true))
      dispatch(setTableButton(false))
    }
  }
  const toggleImport = () => {
    if (buttonState === ButtonState.IMPORT) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setImportButton(false))
      dispatch(setShowImport(false))
    }
    else {
      dispatch(setButtonState(ButtonState.IMPORT))
      dispatch(setImportButton(true))
      dispatch(setShowImport(true))
    }
  }
  const toggleExport = () => {
    if (!selectedLayerTable?.perm) {
      toast.current?.show({ severity: 'error', detail: t("MESSAGE.GottaSelectLayer"), life: 5000 });
    }
    else {
      if (selectedLayerTable.perm >= 3) {
        if (buttonState === ButtonState.EXPORT) {
          dispatch(setButtonState(ButtonState.NONE))
          dispatch(setExportButton(false))
          dispatch(setShowExport(false))
        }
        else {
          dispatch(setButtonState(ButtonState.EXPORT))
          dispatch(setExportButton(true))
          dispatch(setShowExport(true))
        }
      }
      else {
        toast.current?.show({ severity: 'error', detail: t("MESSAGE.NotAllowedForThisOp"), life: 5000 });
      }
    }
  }
  const togglePrint = () => {
    if (buttonState === ButtonState.PRINT) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setPrintButton(false))
      dispatch(setShowPrint(false))
    }
    else {
      dispatch(setButtonState(ButtonState.PRINT))
      dispatch(setPrintButton(true))
      dispatch(setShowPrint(true))
      dispatch(setSidebarButton(true))
    }
  }

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      dispatch(setButtonState(ButtonState.FULLSCREEN))
    }
    else {
      dispatch(setButtonState(ButtonState.NONE))
    }
  }
  const toggleRoadProfile = () => {
    if (buttonState === ButtonState.ROAD_PROFILE) {
      dispatch(setButtonState(ButtonState.NONE))
    }
    else {
      dispatch(setButtonState(ButtonState.ROAD_PROFILE))
    }
  }

  const logOutConfirm = () => {
    // dispatch(setLogOff(true));
    navigate("login")
    localStorage.setItem('zoom', 6.7.toString())
    localStorage.removeItem('token')
  }
  const onLogoutClick = () => {
    confirmDialog({
      message: t('CONFIRMATION.Logout'),
      header: t('HEADER.Confirmation'),
      icon: 'pi pi-exclamation-triangle',
      className: 'attach-confirm-dialog logout-confirm',
      accept: async () => {
        try {
          logOutConfirm()
        } catch (error) {
          console.error(error);
        }
      },
      reject: () => { },
      acceptLabel: `${t('BUTTON.Yes')}`,
      rejectLabel: `${t('BUTTON.Cancel')}`
    });
  }

  const handleSearch = (e: any) => {
    if (e) {
      setSearchText(''); dispatch(setSelectedFeature([]));
      setQuickSearchList([])
      dispatch(setVertexFeature([]))
    }

  }
  const metricTypeChange = (value: any) => {
    (window as any).panogl.getDrawingPlugin().scalable.__sketchLayer.metric=+value;
    setMetricType(+value)
    // const metric :any = localStorage.getItem("setMetricType") ? localStorage.getItem("setMetricType") : '3'
    dispatch(setChildKey(childKey+1))

    const layer_:any =(window as any).olMap.getAllLayers().find((el:any)=> el.getProperties().alias === 'draws')

    for (const feature of layer_.getSource().getFeatures()){

      let calc:number;
/*       switch (metric) {
        case '1':
          calc = +(feature.getStyle().getText().getText().split(' ')[0]) / 0.000621371
        break;
    
        case '2':
          calc = +(feature.getStyle().getText().getText().split(' ')[0]) / 3.28084
        break;
    
        case '4':
          calc = +(feature.getStyle().getText().getText().split(' ')[0]) * 1000
        break;
      
        default:
          calc = +(feature.getStyle().getText().getText().split(' ')[0])
          break;
      }
 */
      const _geomClone: any = feature.getGeometry().clone()

    if(_geomClone.getType()==='LineString'){

      calc = getLength(_geomClone)

      // console.log("getLength: ,",getLength(_geomClone));

      let res:string;
      switch (value) {
        case '1':
          res = (calc * 0.000621371).toFixed(3) + ' mile'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '2':
          res = (calc * 3.28084).toFixed(3) + ' feet'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '4':
          res = (calc / 1000).toFixed(3) + ' km'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '3':
          res = (calc).toFixed(3).toString() + ' meter'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
      
        default:
          break;
      }

    }else{
            
      calc = getArea(_geomClone)

      // console.log("area: ,",getArea(_geomClone));
      
      let res:string;
      switch (value) {
        case '1':
          res = (calc * 0.0000003861).toFixed(5) + ' mile²'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '2':
          res = (calc * 10.763914692).toFixed(5) + ' feet²'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '4':
          res = (calc * 0.000001).toFixed(5) + ' km²'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
        case '3':
          res = (calc).toFixed(5).toString() + ' meter²'
          feature.getStyle().getText().setText(res)
          feature.setStyle(feature.getStyle())
          break;
      
        default:
          break;
      }

    }

    }

      localStorage.setItem("setMetricType",value);

  }
  return (
    <div className=''>

      <div className="Navbar non-printable ">
        <div className='content'>
          <div className='group-1'>
            <div className='sidebar-box'>
              <Button
                icon='selected-icons/layers.svg'
                className={sidebarVisibility ? 'active-button' : ''}
                onClick={toggleSidebar}
                tooltipOptions={{ position: 'bottom' }}
                tooltip='Layers'>
                <img src='icons/layers.svg' alt="" id='layers-svg' />
              </Button>

              <div className='text non-visible'>
                {GIS_PROJECT}
              </div>
            </div>
          </div>
          <div className='vertical-line-group non-visible'></div>
          <div className='group-1bucuk non-visible'>
            <div className='zemin-box'>
              <Dropdown
                className='pano-type-dropdown'
                value={panoType}
                options={PanoTypes}
                onChange={(e) => togglePanoType(e.value)}
                optionLabel="label"
              />
            </div>
          </div>
          <div className='vertical-line-group non-visible'></div>
          <div className='group-2'>
            <div className='digi-button-box'>
              
              {+(window as any).innerWidth <= 680 &&
                digiButtons.filter((button:any)=>button.state === ButtonState.ADD || button.state === ButtonState.DELETE).map((button: any) =>
                  <Button key={button.svg} 
                    // style={{ display: isMobileScreen && button.state !== ButtonState.ADD && button.state !== ButtonState.DELETE ? "none" : "block"}}
                    className={buttonState === button.state ? 'active-button' : ''}
                    onClick={() => {
                      if(button.state === ButtonState.ADD){
                        dispatch(setSelectedLayer(selectedLayerTable))
                        if(isMobileScreen){
                            dispatch(setSidebarButton(false));
                        }
                      }
                      // console.log(" (window as any).drawStartOfDigi = false; ");
                      (window as any).drawStartOfDigi = false; // 
                      dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                      // cancelDigiActivate()
                      changeDigiOperations(button)
                    }}
                    tooltipOptions={{ position: 'bottom' }}
                    tooltip={t(`BUTTON.${button.state}`)}
                    disabled={(button.state === ButtonState.ADD && !selectedLayerTable.name) || button.isDisabled || digiPopupVisibility}>
                    <img src={button.svg} alt="" />
                  </Button>)
              }
              {+(window as any).innerWidth > 680 &&
                digiButtons.map((button: any) =>
                  <Button key={button.svg} 
                    // style={{ display: isMobileScreen && button.state !== ButtonState.ADD && button.state !== ButtonState.DELETE ? "none" : "block"}}
                    className={buttonState === button.state ? 'active-button' : ''}
                    onClick={() => {
                      if(button.state === ButtonState.ADD){
                        dispatch(setSelectedLayer(selectedLayerTable))
                        if(isMobileScreen){
                            dispatch(setSidebarButton(false));
                        }
                      }
                      // console.log(" (window as any).drawStartOfDigi = false; ");
                      (window as any).drawStartOfDigi = false; // 
                      dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                      // cancelDigiActivate()
                      changeDigiOperations(button)
                    }}
                    tooltipOptions={{ position: 'bottom' }}
                    tooltip={t(`BUTTON.${button.state}`)}
                    disabled={( (!selectedLayerTable.name || selectedLayerTable.name === "panogps")) || button.isDisabled || digiPopupVisibility}>
                    <img src={button.svg} alt="" />
                  </Button>)
              }
              <Button
                className={buttonState === ButtonState.HOLE ? 'active-button non-visible' : 'non-visible'}
                onClick={toggleHole}
                disabled={(selectedFeature.length === 0 ||
                  (selectedFeature[0]?.id?.split('.')[0] !== selectedLayerTable.name ||
                    !selectedFeature[0]?.geometry?.type.includes('Polygon') ||
                    !selectedLayerTable.geomtype.includes('Polygon') ||
                    !isLayerVisible ||
                    !selectedLayerTable.name ||
                    selectedTableRow 
                    || digiPopupVisibility
                  ))}
                tooltipOptions={{ position: 'bottom' }}
                tooltip={t(`BUTTON.HOLE`)}>
                <img src='icons/add-object-icons/delikli_poligon.svg' alt="" />
              </Button>
            </div>
            <div className='vertical-line-box non-visible'></div>

            <div className='selection-box non-visible'>
              <Button
                className={buttonState === ButtonState.INFO ? 'active-button' : ''}
                onClick={toggleInfo}
                disabled={!isLayerVisible || digiPopupVisibility}
                tooltipOptions={{ position: 'bottom' }}
                tooltip={t(`BUTTON.INFO`)}>
                <img src='icons/info.svg' alt="" id="info-svg" />
              </Button>
              <Button
                className={buttonState === ButtonState.SELECTION ? 'active-button' : ''}
                disabled={!isLayerVisible || digiPopupVisibility}
                onClick={toggleSelection}
                tooltipOptions={{ position: 'bottom' }}
                tooltip={t(`BUTTON.Selection`)}>
                <img src='icons/selection.svg' alt="" id="selection-svg" />
              </Button>
              <Button
                className={tableVisibility ? 'active-button' : ''}
                disabled={!tableVisibility && !selectedLayerTable.name || digiPopupVisibility} 
                onClick={toggleTable}
                tooltipOptions={{ position: 'bottom' }}
                tooltip={t(`BUTTON.TABLE`)}
              >
                <img src='icons/table.svg' alt="" id="table-svg" />
              </Button>
            </div>
          </div>
          <div className='vertical-line-group'></div>

          <div className='group-3 '>
            <div className='group-3-1'>
              <div className='draw-box'>
                {
                  drawButtons.map((button: any) =>
                    <Button id={button.svg} key={button.svg}
                      className={drawState.drawStateType === button.state && button.state !== DrawStateType.CLEAR ? 'active-button' : ''  }
                      onClick={() => {
                        //  cancelDigiActivate()
                        // console.log("  button.state",button.state);
                        if ((digiPopupVisibility && (window as any).buttonState === ButtonState.ADD)) {
                          
                          (window as any).drawStartOfDigi = true;
                          (window as any).drawWithDigi = true;
                          dispatch(setDrawWithDigi(true))
                        } else {
                          // console.log("isMobileScreen",isMobileScreen);

                          (window as any).drawStartOfDigi = false;
                          if((window as any).buttonState !== ButtonState.EDIT && (window as any).buttonState !== ButtonState.INFO && !isMobileScreen){
                            dispatch(setButtonState(ButtonState.NONE))
                          }
                          if(isMobileScreen && (window as any).buttonState === ButtonState.ADD && 
                          (button.state === DrawStateType.POINT || button.state === DrawStateType.LINESTRING || button.state === DrawStateType.POLYGON)){
                            dispatch(setButtonState(ButtonState.NONE))
                          }
                        }
                        // console.log("drawState :",drawState);
                        
                        changeDrawState(button.state)
                      }}
                      tooltipOptions={{ position: 'bottom' }}
                      tooltip={t(`BUTTON.${button.state}`)}
                    // disabled={digiPopupVisibility}
                    >
                      <img src={button.svg} alt="" />
                    </Button>
                  )}
              </div>
              <div className='vertical-line-box non-visible'></div>

            </div>
            <div className='group-3-2 non-visible'>
              <div className='export-box'>
                <Button
                  className={buttonState === ButtonState.IMPORT ? 'active-button' : ''}
                  onClick={toggleImport}
                  tooltip={t(`BUTTON.Import`)}
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ width: '30px', minWidth: '30px' }}
                  disabled={digiPopupVisibility}
                >
                  <img src='icons/import-button.svg' alt="" id='import-button-svg' />
                </Button>
                <Button
                  className={buttonState === ButtonState.PRINT ? 'active-button' : ''}
                  onClick={togglePrint}
                  tooltip={t(`BUTTON.Print`)}
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ width: '30px', minWidth: '30px' }}
                  disabled={digiPopupVisibility}
                >
                  <img src='icons/print.svg' alt="" id='print-svg' />
                </Button>

                <Button
                  className={buttonState === ButtonState.EXPORT ? 'active-button' : ''}
                  onClick={toggleExport}
                  tooltip={t(`BUTTON.Export`)}
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ width: '30px', minWidth: '30px' }}
                  disabled={digiPopupVisibility}
                >
                  <img src='icons/export-button.svg' alt="" id="export-button" />
                </Button>
              </div>
              <div className='vertical-line-box'></div>
              <div className='way-profile-box'>
                <Button
                  className={buttonState === ButtonState.ROAD_PROFILE ? 'active-button' : ''}
                  onClick={toggleRoadProfile}
                  tooltip={t('BUTTON.Road Profile')}
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ width: '30px', minWidth: '30px' }}
                  disabled={digiPopupVisibility}
                >
                  <img src='icons/road-profile.svg' alt="" />
                </Button>
              </div>
              <div className='vertical-line-box'></div>
              <div className='access-box'>
                <Button
                  className={buttonState === ButtonState.HELP ? 'active-button' : ''}
                  onClick={toggleHelp}
                  tooltip={t(`BUTTON.Help`)}
                  tooltipOptions={{ position: 'bottom' }}
                  icon='pi pi-question'
                  style={{ width: '30px', minWidth: '30px' }}
                  disabled={digiPopupVisibility}
                />
                <Button
                  className={buttonState === ButtonState.TOUR ? 'active-button' : ''}
                  onClick={toggleTour}
                  tooltip={t(`BUTTON.Tour`)}
                  tooltipOptions={{ position: 'bottom' }}
                  style={{ display: 'flex', justifyContent: 'center', width: '30px', minWidth: '30px', alignItems: 'center' }}
                  disabled={digiPopupVisibility}
                >
                  <span
                    style={{ color: '#fff', fontSize: '1.5rem', textAlign: 'center' }}
                  >
                    !
                  </span>
                </Button>
                <Button
                  className={screenshotVisibility ? 'active-button' : ''}
                  onClick={() => { dispatch(setScreenshotButton(true)) }}
                  tooltipOptions={{ position: 'bottom' }}
                  tooltip={t(`BUTTON.Screenshot`)}
                  style={{ width: '30px', minWidth: '30px' }}
                // disabled={digiPopupVisibility}
                >
                  <img src='icons/screenshot.svg' alt="" />
                </Button>
              </div>
            </div>
          </div>
          <div className='vertical-line-group non-visible'></div>
          <div className='group-4 non-visible'>
            <div className='search-box'>
              <span className="p-input-icon-right">
                <i className={`${searchText ? 'pi pi-times' : ''}`} onClick={(e) => handleSearch(e)} />
                <InputText className='search-input' value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={t('BUTTON.Search')} />
                {
                  quickSearchList.length > 0 &&
                  <ListBox options={quickSearchList} onChange={clickOnQuickSearch} optionLabel={quickSearchList[0].search_field}
                    style={{ position: 'absolute', width: '100%', textAlign: 'left', maxHeight: '500px', overflow: 'auto' }}>
                  </ListBox>
                }
              </span>
            </div>
            <div className='vertical-line-box'></div>
            <div className='choose-box'>

              <Language />
              {/* <Button
            className={buttonState === ButtonState.FULLSCREEN ? 'active-button' : ''}
            onClick={() => { toggleFullScreen(); dispatch(setIsFullScreen(!isFullScreen)) }}
            tooltipOptions={{ position: 'bottom' }}
            tooltip={t(`BUTTON.Full Screen`)}>
            <img src='icons/full-screen.svg' alt="" style={{ marginLeft: '4px' }} />
            </Button> */}
              <Button
                className='logout-button'
                onClick={() => setDisplayMenu(!displayMenu)}
                tooltipOptions={{ position: 'bottom' }}
              >
                <span>{user.firstname + ' ' + user.lastname}</span>

              </Button>
              <ConfirmDialog />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="ListBox"
        style={{ position: "absolute", top: 60, right: 200, minWidth: '22em', maxWidth: '22em'  }}
        header="Ayarlar"
        visible={displaySettings}
        modal={false}
        onHide={() => { setDisplaySettings(false); }}
      >
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p>{t('TEXT.Swap Coordinates')} (x,y ⇄ y,x )</p>
          <Checkbox checked={googleCoordinateMode} onChange={(e: any) => { setGoogleCoordinateMode(e.checked) }} />
        </span>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p>{t('TEXT.Memorize')}</p>
          <Checkbox checked={enabledByMemorize} onChange={(e: any) => { dispatch(setEnabledByMemorize(e.checked)) }} />
        </span>
        <div className='lidar-container'>
        <p>{t('TEXT.Metric')}</p>
          <div className="tooltip-setting">
              {
                  metricSettings.map((setting: any, index: any) =>
                      <div key={setting.label} className="line">
                          <label htmlFor={setting.label}>{setting.label}</label>
                          <input onChange={(e: any) => metricTypeChange(e.target.value)}
                              type="radio" name="screen-type" checked={metricType === +setting.value ? true : false}
                              value={setting.value} className="form-radio" />
                      </div>
                  )
              }
          </div>
        </div>

        <Button className="Change-Password-Button" label={t("BUTTON.Change Password")} icon="pi pi-key" onClick={()=> {setPasswordForm(true)}} />
        
          
        <PasswordChangeForm 
                visible={IsPasswordForm}
                onHide={handlePasswordFormClose}
            />

      
        
      </Dialog>
      {displayMenu &&
        <div className="menu">
          <Menu model={user.isadmin ? [{ label: 'Panel', icon: 'pi pi-th-large', url: `${process.env.REACT_APP_BASE_URL}panel` }, ...menuItems] : menuItems} />
        </div>}
      <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
      {/* <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '444444 !important' }} /> */}
    </div>
  )
}
