import './Table.scss';
import { useState, useEffect, useRef } from 'react';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { setButtonState, setTableButton } from '../../../state/features/buttonStateSlice';
import { setGoToSelection, setSelectedRow, setTableExtent } from '../../../state/features/tableSlice';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { useTranslation } from 'react-i18next';
import { ButtonState } from '../button-state/ButtonState';
import { setSelectedLayer, setSelectedLayerGeoserverUrl, setWorkspace } from '../../../state/features/layerSlice';
import { setConfirmPopupVisibility, setMultipleDigiStatus } from '../../../state/features/digitizationSlice';
import { IField } from '../../../util/model/layer';
import { MultiSelect } from 'primereact/multiselect';
import axios from 'axios';
import moment from 'moment';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import { WKT } from 'ol/format';
import * as olExtent from 'ol/extent';;
interface SortProperties {
    sortField: string,
    sortOrder: DataTableSortOrderType
}

/* 
 * Table ın oluşturulduğu ve table işlemlerinin (düzenle, geometri düzenle, sil ) yapıldığı component.
 *
*/
export const Table = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dt: any = useRef(null);
    const [columns, setColumns] = useState<JSX.Element[]>([])
    const [tableData, setTableData] = useState<any>([])
    const [values, setValues] = useState<any>([])
    const [rowSelection, setRowSelection] = useState<any>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [filterClear, setFilterClear] = useState<boolean>(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [otherFilter, setOtherFilter] = useState<any>([]);
    const [createdByFilter, setCreatedByFilter] = useState<any>([]);
    const [touchByFilter, setTouchByFilter] = useState<any>([]);
    const [allFilters, setAllFilters] = useState<any>([]);

    
    const [sortProperties, setSortProperties] = useState<SortProperties>({
        sortField: 'id',
        sortOrder: 1
    });
    const [filters, setFilters] = useState({});
    const [allUsers, setAllUsers] = useState<any>([]);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayerTable);
    const transactStatus = useAppSelector(state => state.digitization.transactStatus);
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus);
    const geoserverUrl: any = useAppSelector(state => state.layer.geoserverUrlTable);
    const selectedTableRow = useAppSelector(state => state.table.selectedRow);
    const services: any = useAppSelector(state => state.layer.services);
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate);
    const multipleDigiStatus: any = useAppSelector(state => state.digitization.multipleDigiStatus);
    const token = localStorage.getItem('token');
    const { REACT_APP_BASE_URL } = process.env
    const [selectedFeature]: any = useAppSelector((state) => state.selectedFeature.feature);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const displayPrint = useAppSelector((state) => state.buttonState.printButton);
    const generalSelectUrl = REACT_APP_BASE_URL + `rest/v1/util/generalSelectForLayerTable`;
    const projects: any = useAppSelector(state => state.layer.projects);
    const [tableOpened, setTableOpened] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setTableOpened(true)
        }, 1000);
    }, []);

    useEffect(() => {
        if (!selectedFeature) {
            setRowSelection([])
        }
    }, [selectedFeature]);
    useEffect(() => {
        if (allUsers.length) {
            dispatch(setGoToSelection(false))
            setTableLoading(true);
            dispatch(setSelectedRow(false))
            dispatch(setMultipleDigiStatus(true))

            let filteredTableFields: any = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom' && obj.name !== 'atta')
            let tableFields: any = filteredTableFields.sort((a: any, b: any) => a.priority - b.priority);
            const columns = []
            const filters: any = {}
            columns.push(<Column selectionMode="multiple" className='test' headerClassName='test' bodyClassName='test' headerStyle={{ width: '5px' }} bodyStyle={{ width: '5px' }} />)
            for (const field of tableFields) {
                switch (field.type) {
                    case 'Text':
                    case 'Email':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'Attachment':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'Area':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                        columns.push(<Column filter dataType="numeric" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'CreatedBy':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'CreatedDate':
                    case 'Date':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
                        columns.push(<Column filter dataType="date" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias}
                            body={(rowData) => { return dateBodyTemplate(rowData, field.name) }} filterElement={dateFilterTemplate} />)
                        break;
                    case 'TouchBy':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'TouchDate':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
                        columns.push(<Column filter dataType="date" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias}
                            body={(rowData) => { return dateBodyTemplate(rowData, field.name) }} filterElement={dateFilterTemplate} />)
                        break;
                    case 'Domain':
                        columns.push(<Column filter showFilterMatchModes={false} sortable key={field.id} field={field.name} header={field.alias}
                            filterElement={(options) => { return domainFilterTemplate(options, field) }} />)
                        filters[field.name] = { value: null, matchMode: FilterMatchMode.IN }
                        break;
                    case 'Integer':
                    case 'Float':
                        if (field.name === 'il_kodu' || field.name === 'ilce_kodu' || field.name === 'mahalle_kodu' || field.name === 'tkgm_il_kodu' || field.name === 'tkgm_ilce_kodu' || field.name === 'tkgm_mahalle_kodu') {
                            filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                            columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        }
                        else {
                            filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                            columns.push(<Column dataType="numeric" filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        }
                        break;
                    default:
                         filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                        // columns.push(<Column sortable key={field.id} field={field.name} header={field.alias} />)
                        columns.push(<Column filter dataType="numeric" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                }
            }

            setColumns(columns);
       
            setTimeout(() => {
                setFilters(filters);
                getFeatures();
                if (filterClear) {
                    setFilterClear(false)
                }
            }, 100);
        }
    }, [filterClear, allUsers])

    useEffect(() => {
        setFilters({})
        setSortProperties({
            sortField: 'id',
            sortOrder: 1
        })
        // setAllFilters
        setAllFilters([]);
        //setFilterClear(true)
        // setOtherFilter([]); setCreatedByFilter([]); setFilterClear(true)
        setTimeout(() => {
            setTableOpened(true)
        }, 100);
    }, [selectedLayer]);

    useEffect(() => {
        if(tableOpened){
            clearSelectedData()
            getFeatures()
        }
    }, [allFilters, first, rows, sortProperties, transactStatus, panoramaTransactStatus, multipleDigiStatus])

    useEffect(() => {
        if (!selectedTableRow) {
            dispatch(setSelectedFeature([]))
            setRowSelection([])
            dispatch(setGoToSelection(false))
        }
    }, [selectedTableRow])

    useEffect(() => {
        if (rowSelection.length > 0) {
            dispatch(setButtonState(ButtonState.NONE))
            selectionHandler(rowSelection)
        } else {
            dispatch(setSelectedFeature([]))
            dispatch(setSelectedRow(false))
            dispatch(setGoToSelection(false))
        }
    }, [rowSelection])

    useEffect(() => {
        getUsers()
    }, [token])

    // useEffect(() => {
    //     if ((otherFilter && otherFilter.length > 0) || (createdByFilter && createdByFilter.length > 0) || (touchByFilter && touchByFilter.length > 0)) {
    //         setTimeout(() => {
    //             setAllFilters([...otherFilter, ...createdByFilter, ...touchByFilter])
    //         }, 1000);
    //     } else {
    //         setAllFilters([...otherFilter, ...createdByFilter, ...touchByFilter])
    //     }
    // }, [otherFilter, createdByFilter, touchByFilter])

    useEffect(() => {
        if (cancelDigiActivate) {
            dispatch(setSelectedFeature([]))
            setRowSelection([])
        }
    }, [cancelDigiActivate])

    const goToSelectedData = async () => {
        dispatch(setGoToSelection(true))
    }

    const getFeatures = async () => {
        setTableData([]);
        setTableLoading(true)
        let features: any;
        let score: any;

        const url_s : any= geoserverUrl.split('/');
        let workspace : any=  url_s[url_s.length -1]

        const filterKeys : any = [];
        if(allFilters && allFilters.length > 0){
            allFilters.forEach((element: any) =>{
                if(element.key === 'createdDate' || element.key === 'touchDate'){
                    if(allFilters.find((el:any)=> el.key === element.key).value){
                        const rule:any = element.value.constraints[0].matchMode
                        const test :any = rule === "dateIs" || rule === "dateIsNot" || rule === "dateBefore" || rule === "dateAfter";
                        if(test){
                            filterKeys.push(
                                {
                                    "key": element.key,
                                
                                    "rule": rule === "dateIs" ? 'equals' : rule === "dateIsNot" ? 'notEquals' : rule === "dateBefore" ? 'before' : rule === "dateAfter" ? 'after' : '',
                                    
                                    "value": element.value.constraints[0].value.substring(1,element.value.constraints[0].value.length-1).substring(0,10),

                                    "conversion": "CAST(col AS DATE)"
                                }
                            )
                        }
                        
                    }
                }else 
                if(element.key === 'createdBy' || element.key === 'touchBy'){
                    if(allFilters.find((el:any)=> el.key === element.key).value){
                        
                        // console.log(  {
                        //     "key": element.key,
                        
                        //     "rule": "in",
                            
                        //     "value": element.value
                        // });
                        
                        filterKeys.push(
                            {
                                "key": element.key,
                            
                                "rule": "in",
                                
                                "value": element.value
                            }
                        )
                    }
                }else 
                if(element.value.constraints){
                    if(element.value.constraints[0].value){

                        if (element.value.constraints[0].matchMode === 'lt') {
                            element.value.constraints[0].matchMode = 'smaller'
                        }
                        if (element.value.constraints[0].matchMode === 'lte') {
                            element.value.constraints[0].matchMode = 'smallerEquals'
                        }
                        if (element.value.constraints[0].matchMode === 'gt') {
                            element.value.constraints[0].matchMode = 'greater'
                        }
                        if (element.value.constraints[0].matchMode === 'gte') {
                            element.value.constraints[0].matchMode = 'greaterEquals'
                        }
                        filterKeys.push(
                            {
                                "key": element.key,
                            
                                "rule": element.value.constraints[0].matchMode,
                                
                                "value": element.value.constraints[0].value
                            }
                        )
                    }
                }else if(element.value.value && element.value.value.length){
                    const valueArray :any = element.value.value
                    // * * * * * const field :any = selectedLayer.fields.find((field:any)=> field.name === element.key)
                        let domainVals:any = "";
                        valueArray.forEach((val:any) => {
                            if(val === 'null' || val === 'notNull'){
                                domainVals += val + ";";
                            }else{
                                domainVals += "'" + val + "';";
                            }
                        });
                        domainVals = domainVals.slice(0, -1)

                        filterKeys.push(
                            {
                                "key": element.key,
                            
                                "rule": element.value.matchMode,
                                
                                "value": "("+domainVals+")" 
                            }
                        )
                    }
            })

        }
       // console.log("selectedLayer :",selectedLayer);
       const _ands : any = []
       for (let index = 0; index < filterKeys.length-1; index++) {
           _ands.push("and")
       }


        const copyProjects: any = [...projects]
        copyProjects.map((project: any) => {
          if (project.layer_groups && project.layer_groups.length > 0 && project.name === "default_project") {
            project.layer_groups.map((layerGroup: any) => {
              layerGroup.layers.map((layer: any) => {
                    if(layer.name === selectedLayer.name){
                        workspace = 'public'
                        // console.log("workspace :",workspace);
                    }
                })
              })
            }
        })
        const sortPropertiesCopy = structuredClone(sortProperties)
        if (sortPropertiesCopy.sortField === 'created_by' || sortPropertiesCopy.sortField === 'createdBy') {
            sortPropertiesCopy.sortField = 'created_name'
        }
        if (sortPropertiesCopy.sortField === 'touch_by' || sortPropertiesCopy.sortField === 'touchBy') {
            sortPropertiesCopy.sortField = 'touch_name'
        }
        const body = {
            "db": "data",
            "tableNames":[`${workspace}.${selectedLayer.name}`],
            "columnsToGet": [["*","geom"]],
            "columnsToGetAlias" : [["","wktGeom"]],
            "columnsToGetConversions": [["","ST_ASTEXT(col)"]],
            "filterKeys": [filterKeys],
            "filterOperations": [_ands],
            "relationTypes": [],
            "relationKeys":  [],
            "relationKeysConversions": [],
            "order": [[{ "key": sortPropertiesCopy?.sortField, "mode": sortPropertiesCopy?.sortOrder === 1 ? "asc" : "desc" }]],
            "limit": String(rows),
            "offset": String(first)
        }
      
        let contrl:any = true;
        let sortContrl:any = true;
        sortContrl = selectedLayer.fields.find((el:any)=>el.name === sortProperties?.sortField)
      
        if(filterKeys.length){
            filterKeys.forEach((key:any) => {
                contrl = selectedLayer.fields.find((el:any)=>el.name === key.key)
            });
        }
        if(contrl && sortContrl){
            try {
            const response2 = await axios.post(generalSelectUrl + `?token=${token}`,body)
            // const response = await axios.get(geoserverUrl +
            //     `/ows?service=WFS&version=1.0.0&srsname=EPSG:3857&maxFeatures=${rows}&startIndex=${first}&${allFilters && allFilters.length > 0 && `cql_filter=${allFilters.map((element: any) => `(${element.key}${element.matchMode}${element.value})`).join(' AND ')}`}&sortBy=${sortProperties.sortField + (sortProperties.sortOrder === 1 ? '+A' : '+D')}
            //     &version=1.0.0&request=getfeature` + (token ? `&token=${token}` : '') + `&outputFormat=application/json&typeName=${selectedLayer.name}`)
            features = response2.data.data.data
            score = response2.data.data.total_count;
            const wkt = new WKT();
            const geomArray = features.map((feature:any)=>{
                const geom :any= wkt.readGeometry(feature.wktgeom);
                return geom.transform('EPSG:4326', 'EPSG:3857');

            })
            // Assuming you have your array of geometries named 'geometries'
            const geometries:any = [...geomArray] // Array of geometries in EPSG:4326 format
            // Calculate the bounding box (bbox) that contains all geometries
            let extent: any = geometries[0].getExtent();
            geometries.forEach((geometry:any) => {
                const geomExtent = geometry.getExtent();
                extent = extent ? olExtent.extend( extent, geomExtent) : geomExtent;
            });

            dispatch(setTableExtent(extent))
            features?.forEach((feature: any) => {
                feature.properties = feature
            });
                
            const properties = features?.map((feature: any) => feature.properties);
            let attachmentArray: any[] = []
            if (properties) {
                try {
                    const attachmentsResponse = await AttachmentRestApi.getAttachmentListByLayerId(selectedLayer.id)
                    attachmentArray = (attachmentsResponse.data && attachmentsResponse.data.data) ? attachmentsResponse.data.data : []
                } catch (error) {
                    console.log(error)
                }
            }

            if (features) {
                const fields = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom');
                const values: any = [];
                const attachmentArr = attachmentArray;
                let attachmentsNameArr: any[] = []
                var areaFormatter: any = new Intl.NumberFormat();
                for (const feature of features) {
                    const properties: any = {}

                    for (const field of fields) {
                        switch (field.type) {

                            case 'Attachment':
                                for (const attach of attachmentArr) {
                                    if (attach.feature_id === feature.properties.id) {
                                        attachmentsNameArr = []
                                        attachmentsNameArr.push(attach.filename)
                                        properties[field.name] = attachmentsNameArr.join(', ');
                                    }
                                }
                                break;
                            case 'Domain':
                                properties[field.name] = field.domain?.find((obj: any) => obj.id === feature.properties[field.name])?.desc;
                                break;
                            case 'CreatedBy':
                                properties[field.name] = allUsers.find((obj: any) => obj.id === feature.properties[field.name])?.username;
                                break;
                            case 'CreatedDate':
                                properties[field.name] = feature.properties[field.name] !== 'Invalid Date' ? new Date(feature.properties[field.name]) : '';
                                break;
                            case 'TouchBy':
                                properties[field.name] = allUsers.find((obj: any) => obj.id === feature.properties[field.name])?.username;
                                break;
                            case 'TouchDate':
                                properties[field.name] = feature.properties[field.name] !== 'Invalid Date' ? new Date(feature.properties[field.name]) : '';
                                break;
                            case 'Area':
                                properties[field.name] = feature.properties[field.name] ? areaFormatter.format(feature.properties[field.name].toFixed(2)) : '';
                                break;
                            case 'Float':
                                properties[field.name] = feature.properties[field.name] ? areaFormatter.format(feature.properties[field.name].toFixed(2)) : '';
                                break;
                            default:
                                properties[field.name] = feature.properties[field.name];
                                break;
                        }
                    }
                    values.push(properties)
                }
                setValues([...values])

                setTotalRecords(score ? score : features.length);
                setTableLoading(false);
            } else {
                setValues([]);
                setTableLoading(false);
            }

        } catch (error) {
            setValues([]);
            setTableLoading(false);
        }
        }
    }

    const onPage = (event: any) => {
        setRows(event.rows);
        setFirst(event.first);
    }

    const onSort = (event: any) => {
        setSortProperties({ sortField: event.sortField, sortOrder: event.sortOrder })
    }

    const onFilter = async (event: any) => {
  /*       if(selectedLayer.name === "panogps"){
            return
        } */
        const filters = structuredClone(event.filters);
        const allFilters: any = []

        for (const [key, value] of Object.entries(filters)) {
            allFilters.push({ key, value })
        }
        
        const result = await createFilters(allFilters)
        const usersFilters = result.filter((filter: any) => filter.key === 'createdBy')
        const usersFilters2 = result.filter((filter: any) => filter.key === 'touchBy')
        const otherFilters = result.filter((filter: any) => filter.key !== 'createdBy' && filter.key !== 'touchBy')

        const res = await searchInUsers2(filters.createdBy?.constraints[0], usersFilters)
        const res2 = await searchInUsers2(filters.touchBy?.constraints[0], usersFilters2)
        
        if(!allFilters.find((el:any)=> el.key==='createdDate')){
            allFilters.push({key: 'createdDate', value: {}})
            filters['createdDate']=
            {
                "operator": "and",
                "constraints": [
                    {
                        "value": null,
                        "matchMode": "startsWith"
                    }
                ]
            }
        }
        if(!allFilters.find((el:any)=> el.key==='touchDate')){
            allFilters.push({key: 'touchDate', value: {}})
            filters['touchDate']=
            {
                "operator": "and",
                "constraints": [
                    {
                        "value": null,
                        "matchMode": "startsWith"
                    }
                ]
            }
        }
        if(!allFilters.find((el:any)=> el.key==='createdBy')){
            allFilters.push({key: 'createdBy', value: {}})
            filters['createdBy']=
            {
                "operator": "and",
                "constraints": [
                    {
                        "value": null,
                        "matchMode": "startsWith"
                    }
                ]
            }
        }
        if(!allFilters.find((el:any)=> el.key==='touchBy')){
            allFilters.push({key: 'touchBy', value: {}})
            filters['touchBy']=
            {
                "operator": "and",
                "constraints": [
                    {
                        "value": null,
                        "matchMode": "startsWith"
                    }
                ]
            }
        }
      
        const datefilter:any = otherFilters.find((el:any)=> el.key=== 'createdDate')
        const touchfilter:any = otherFilters.find((el:any)=> el.key=== 'touchDate')

        if(datefilter){
            allFilters.find((el:any)=> el.key==='createdDate').value.constraints[0].value = datefilter.value
        }else{
            allFilters.find((el:any)=> el.key==='createdDate').value = undefined
        }
        if(touchfilter){
            allFilters.find((el:any)=> el.key==='touchDate').value.constraints[0].value = touchfilter.value
        }else{
            allFilters.find((el:any)=> el.key==='touchDate').value = undefined
        }
        if(res && res.length){
            res[0].value = res[0].value.replaceAll(/,/g,";")
            allFilters.find((el:any)=> el.key==='createdBy').value = res[0].value;
        }else{
            
            setTimeout(() => {
                if(filters.createdBy.constraints[0].value){
                    setValues([]);
                    setTableLoading(false);
                }
            }, 1000);
            
            allFilters.find((el:any)=> el.key==='createdBy').value = undefined
        }
        
        if(res2 && res2.length){
            res2[0].value = res2[0].value.replaceAll(/,/g,";")
            allFilters.find((el:any)=> el.key==='touchBy').value = res2[0].value;
        }else{
            setTimeout(() => {
                if(filters.touchBy.constraints[0].value){
                    setValues([]);
                    setTableLoading(false);
                }
            }, 1000);
            allFilters.find((el:any)=> el.key==='touchBy').value = undefined
        }
        setAllFilters(allFilters);
        setFirst(0);
        /* 
        setCreatedByFilter(res)
        setTouchByFilter(res2)
        setOtherFilter(otherFilters); */
    }

    const createFilters = (allFilters: any) => {
        const usedFilters: any = []

        if (allFilters.length > 0) {
            allFilters.forEach((filter: any) => {
                if (filter.value.constraints && filter.value.constraints.length > 0) {
                    filter.value.constraints.forEach((item: any) => {
                        if (item.value) {
                            if (item.matchMode === 'startsWith') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'${item.value}%25'` });
                            }
                            if (item.matchMode === 'contains') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'%25${item.value}%25'` });
                            }
                            if (item.matchMode === 'notContains') {
                                usedFilters.push({ key: filter.key, matchMode: ' not like ', value: `'%25${item.value}%25'` });
                            }
                            if (item.matchMode === 'endsWith') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'%25${item.value}'` });
                            }
                            if (item.matchMode === 'equals') {
                                usedFilters.push({ key: filter.key, matchMode: '=', value: `'${item.value}'` });
                            }
                            //for dates
                            if (item.matchMode === 'dateIs') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '=', value: `'${localDateTime + 'Z'}'` });
                            }
                            if (item.matchMode === 'dateBefore') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '<', value: `'${localDateTime + 'Z'}'` });
                            }
                            if (item.matchMode === 'dateAfter') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '>', value: `'${localDateTime + 'Z'}'` });
                            }
                        }
                    })
                }
                //for domain
                if (filter.value.value && filter.value.value.length > 0) {
                    const filterValue = filter.value
                    if (filterValue.matchMode === 'in') {
                        const valueArray = filterValue.value
                        usedFilters.push({ key: filter.key, matchMode: '=', value: valueArray.map((value: any) => `${value}`).join(` OR ${filter.key}=`) });
                    }
                };
            });
            return usedFilters
        }
    }

    const searchInUsers2 = async (filter: any, filters: any) => {
        if (filters && filters.length > 0) {
            const usersFilters: any[] = []
            await axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/lazyLoad/${0}/${first}/id/1/${filter.matchMode}/username/${filter.value}`, { params: { token: token } })
                .then((response: any) => {
                    const usersResponse = response.data.users

                    if (usersResponse && usersResponse.length > 0) {

                        for (const filter of filters) {
                            // if (usersResponse.length === 1) {
                            //     usersFilters.push({ key: filter.key, matchMode: '=', value: usersResponse.length > 0 && usersResponse.map((arr: any) => arr.id).join(',') })
                            // }
                            if (usersResponse.length > 0) {
                                usersFilters.push({ key: filter.key, matchMode: ' in ', value: `(${usersResponse.length > 0 && usersResponse.map((arr: any) => arr.id).join(',')})` })
                            }
                        }

                    }
                })
            return usersFilters
        } else { return [] }
    }

    const getUsers = async () => {
        const usersResponse = await axios.get(REACT_APP_BASE_URL + `rest/v1/user?token=${token}`)
        setAllUsers(usersResponse.data.users)
    }

    const selectionHandler = async (selectedFeature: any) => {
        let selectionsArr: any[] = []

        const service = services.find((service: any) => { return service.layers.some((layerObj: any) => layerObj.name === selectedLayer.name) })
        if (service) {
            for (const feature of selectedFeature) {
                try {
                    const response = await axios.get(service!.url + `/ows?service=WFS&version=1.0.0&srsname=EPSG:3857&request=getfeature` + (token ? `&token=${token}` : '') + `&featureId=${feature.id}&typeName=${selectedLayer.name}&outputFormat=application/json`)
                    const findedFeature = response.data.features[0]
                    selectionsArr.push(findedFeature)
                } catch (error) {
                    console.log(error);
                }
            }
        }
        dispatch(setSelectedFeature([...selectionsArr]))
        dispatch(setSelectedRow(true))
    }

    const clearSelectedData = () => {
        dispatch(setSelectedFeature([]))
        setRowSelection([])
    }
    const editSelectedData = () => {
        dispatch(setButtonState(ButtonState.EDIT));
        dispatch(setConfirmPopupVisibility(true));
    }

    function convertArrayOfObjectsToCSV(data: object[]): string {
        data = data || []
        let filteredTableFields: any = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom' && obj.name !== 'atta')
        const headers = Object.keys(data[0]).map((value, index) => filteredTableFields[index] && filteredTableFields[index].alias ? `"${filteredTableFields[index].alias}"` : `""`).join(',');
        const csv = [
            headers,
            ...data.map(row => Object.values(row).map(value => value ? `"${value}"` : `""`).join(','))
        ].join('\n');
        return `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }

    function downloadCSV(data: object[], filename: string): void {
        const csv = convertArrayOfObjectsToCSV(data);
        const link = document.createElement('a');
        link.setAttribute('href', csv);
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const exportTable = () => {
        try {
            if (rowSelection.length === 0 || rowSelection.length === values.length) {
                const valuesFormatted = formatDateForExport(values)
                downloadCSV(valuesFormatted, `${selectedLayer.name}_ALLDATA`)
            } else {
                const rowSelectionFormatted = formatDateForExport(rowSelection)
                downloadCSV(rowSelectionFormatted, `${selectedLayer.name}_SELECTEDONLY`)
            }
        } catch (error) {
            // (toast.current as any).show({ severity: 'error', summary: 'Error Message', detail: 'Tablo cant be export' });
        }
    }

    const close = () => {
        setTableOpened(false)
        dispatch(setTableButton(false))
        // dispatch(setSelectedLayer({}))
       // dispatch(setSelectedLayerGeoserverUrl(''))
        dispatch(setWorkspace(''))
    }

    const dateFilterTemplate = (options: any) => {        
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
    }

    const dateBodyTemplate = (rowData: any, field_name: string) => {
        return formatDate(rowData[field_name]);
    }
        
        
    const domainFilterTemplate = (options: any, field: IField) => {
        const domains:any = [...field.domain, {id: 'null', desc: t('TABLE.Empty')}, {id: 'notNull', desc: t('TABLE.NotEmpty')}]
        return <MultiSelect filter value={options.value}
            options={domains?.map((obj:any) => { return { label: obj.desc, value: obj.id } })}
            onChange={(e) => {options.filterCallback(e.value); }} />
    }

    const formatDate = (value: any): string => {
        if (!value) {
            return ''; // Placeholder for null or undefined values
        }
    
        const date = new Date(value);
    
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date'; // Optional: handle invalid dates gracefully
        }
    
        return date.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };
    

    function isValidDate(dateString: any) {
        // Attempt to create a new Date object from the dateString
        const dateObject = new Date(dateString);
        // Check if the dateObject is valid (not NaN) and the dateString is not empty
        return !isNaN(dateObject.getTime()) && dateString.trim() !== '';
    }
    const formatDateForExport = (values: any) => {
        const copy = [...values]
        for (let i = 0; i < copy.length; i++) {
            const row = copy[i];
            const entries = Object.entries(row);
            
            for (const [key, value] of entries) {
                if (key == 'created_date'|| key == 'touch_date' || key == 'createdDate' || key == 'touchDate' || key == 'createddate' || key == 'touchdate') {
                    if (value instanceof Date) {
                        const newDate = value.toLocaleDateString('tr-TR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZoneName: 'short',
                        });
                        copy[i][key] = newDate
                    }
                }
            }
        }
        return copy
    }

    const header = (
        <div className='header-container'>
            <div className='group'>
                <p>{selectedLayer.alias}</p>
                <Button
                    className="table-button p-button-outlined"
                    label={t('TABLE.Go To Selected Data')}
                    onClick={goToSelectedData}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                />
                <Button
                    className="table-button p-button-outlined p-button-warning"
                    label={t('TABLE.Clear Selection')}
                    onClick={clearSelectedData}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                />
                <Button
                    className="table-button p-button-outlined p-button-success"
                    label={t('TABLE.Edit')}
                    disabled={rowSelection ? rowSelection.length === 0 || selectedLayer.name === "panogps" : false}
                    onClick={editSelectedData}
                />
                <Button
                    className="table-button p-button-outlined p-button-danger"
                    label={t('TABLE.Delete')}
                    disabled={rowSelection ? rowSelection.length === 0 || selectedLayer.name === "panogps"  : false}
                    onClick={() => dispatch(setButtonState(ButtonState.DELETE))}
                />
                <Button
                    className="table-button p-button-outlined p-button-help"
                    label={t('TABLE.Refresh')}
                    onClick={() => getFeatures()}
                />
                <Button
                    className="table-button p-button-outlined p-button-secondary"
                    label={t('TABLE.Clear Filter')}
                    onClick={() => { setAllFilters([]);  setFilterClear(true)/* setOtherFilter([]); setCreatedByFilter([]); setFilterClear(true)*/ }}
                />
                <Button
                    className="table-button p-button-outlined"
                    label={rowSelection.length === 0 || rowSelection.length === values.length ? t('TABLE.Export CSV') : t('TABLE.Export Selected CSV')}
                    onClick={exportTable}
                />
            </div>
            <div className='icon'>
                <i className='pi pi-times' onClick={close}></i>
            </div>
        </div>
    );

    const zart = <div>{t('TABLE.Total Record')} : {totalRecords}</div>
    const zart2 = <div>{t('TABLE.Chosen Rows')} : {rowSelection.length}</div>

    return (
        <div className='card' style={{ paddingBottom: displayPrint ? "3%" : "" }} >
            {tableData &&
                <><DataTable
                    style={{ height: '100%' }}
                    ref={dt}
                    value={values}
                    selection={rowSelection}
                    tableStyle={{ minWidth: '50rem', width: '100%' }}
                    selectionMode={'checkbox'}

                    // onRowClick={(e:any)=>{
                    //     console.log(!rowSelection.find((e:any) => e.id === e.data?.id));

                    //     if(!rowSelection.find((e:any) => e.id === e.data?.id)){
                    //         // setRowSelection([e.data])
                    //         console.log("Hi",e.data?.id);

                    //     }
                    // }}
                    onRowDoubleClick={(e: any) => {
                        setRowSelection([e.data]);
                        setTimeout(() => {
                            dispatch(setGoToSelection(true));
                            setTimeout(() => {
                                setRowSelection([...rowSelection, e.data]);
                            }, 5);
                        }, 100);
                    }}
                    onSelectionChange={(e: any) => {
                        setRowSelection(e.value);
                    }}
                    onPage={onPage}
                    onFilter={onFilter}
                    rows={rows}
                    loading={tableLoading ? true : false}
                    header={header}
                    first={first}
                    filters={filters}
                    onSort={onSort}
                    totalRecords={totalRecords}
                    sortField={sortProperties?.sortField}
                    sortOrder={sortProperties?.sortOrder}
                    paginatorRight={zart}
                    paginatorLeft={zart2}
                    lazy
                    paginator
                    showGridlines
                    stripedRows
                    resizableColumns
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    className='table-style'
                    columnResizeMode='expand'
                    responsiveLayout="scroll"
                    filterDisplay="menu"
                    emptyMessage='No data found.'
                    paginatorPosition="top"
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                >
                    {columns}
                </DataTable></>}
        </div >
    );
}
